//蓝色字体颜色
@common-blue: #4bacff;

//正文颜色
@main-text: #333;

//副标题颜色
@sub-text: #666;

//flex布局-row
.row {
  display: flex;
  flex-direction: row;
}

//flex布局-column
.column {
  display: flex;
  flex-direction: column;
}

//flex垂直水平居中
.flex-center {
  justify-content: center;
  align-items: center;
}

//英文标题
.title-en {
  color: @common-blue;
  font-size: 16px;
}

//中文标题
.title-zh {
  color: @main-text;
  font-size: 38px;
  margin-top: 20px;
}

.sub-text {
  color: @sub-text;
  font-size: 20px;
}

@media screen and (max-width: 1300px) {
  .title-en {
    font-size: 13px;
  }

  .title-zh {
    font-size: 30px;
  }

  .sub-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .title-en {
    font-size: 10px;
  }

  .title-zh {
    font-size: 22px;
    margin-top: 16px;
  }

  .sub-text {
    font-size: 14px;
  }
}
