@import '../common.less';

.application {
  .row;
  margin-top: 180px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    margin-top: 80px;
    flex-flow: wrap;
  }

  .advantage {
    .column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 10px 20px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 700px;
    width: 670px;

    .advantage-text {
      .column;
      align-items: center;
      padding: 0 15%;
    }

    .detail {
      margin-top: 44px;
      text-align: center;
    }

    .advantage-card-box {
      width: 100%;
    }

    .advantage-card {
      .row;
      justify-content: space-between;
      padding: 0 15%;

      .advantage-card-item {
        .column;
        justify-content: space-between;
        align-items: center;
        height: 170px;
        @media screen and (max-width: 480px) {
          height: 100px;
        }
        .advantage-card-image {
          .row;
          justify-content: center;
          width: 58px;
          height: 65px;
          @media screen and (max-width: 480px) {
            width: 37px;
            height: 40px;

            img {
              object-fit: contain;
            }
          }
        }

        .advantage-card-title {
          color: @main-text;
          font-size: 24px;

          @media screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
    }

    @media screen and (max-width: 1440px) {
      .advantage-text {
        padding: 0 10%;
      }

      .advantage-card {
        padding: 0 10%;
      }
    }

    @media screen and (max-width: 1300px) {
      .advantage-card-title {
        font-size: 19px;
      }
    }

    @media screen and (max-width: 480px) {
      height: 380px;
      width: 100%;

      .detail {
        margin-top: 25px;
      }
    }
  }

  .using {
    .column;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    box-sizing: border-box;
    height: 700px;
    width: 670px;
    padding-left: 30px;

    @media screen and (max-width: 480px) {
      width: 100%;
      margin-top: 70px;
      padding-left: 0;
      height: auto;
    }

    .using-text-box {
      .column;
      align-items: center;
    }

    .using-bar-box {
      width: 90%;

      @media screen and (max-width: 480px) {
        width: 98%;
        margin-top: 30px;
      }
    }

    .using-bar {
      .row;
      align-items: center;
      height: 150px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(239, 239, 239, 1);

      @media screen and (max-width: 480px) {
        height: 86px;
      }

      &:not(:first-child) {
        margin-top: 35px;

        @media screen and (max-width: 480px) {
          margin-top: 22px;
        }
      }

      .using-slidebar {
        width: 20px;
        height: 150px;
        margin-right: 25%;
        border-radius: 4px 0px 0px 4px;

        @media screen and (max-width: 1024px) {
          margin-right: 15%;
        }

        @media screen and (max-width: 480px) {
          margin-right: 20%;
        }
      }

      .using-img {
        margin-right: 12%;

        @media screen and (max-width: 1024px) {
          margin-right: 8%;
        }

        @media screen and (max-width: 480px) {
          width: 40px;
          height: 40px;
        }
      }

      .using-text {
        color: @main-text;
        font-size: 24px;

        @media screen and (max-width: 1440px) {
          font-size: 19px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 17px;
        }

        @media screen and (max-width: 480px) {
          font-size: 16px;
        }
      }

      &:hover {
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.04);

        /deep/ .using-slidebar {
          background-color: @common-blue;
        }

        /deep/ .using-text {
          color: @common-blue;
        }
      }
    }
  }

  @media screen and (max-width: 1780px) {
    padding: 0 5%;
  }
}
