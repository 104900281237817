@media screen and (min-width: 640px) {
  .production-video {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    .video-item {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }

      img {
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 8px;
      }

      span {
        color: #666666;
        font-size: 16px;
        vertical-align: middle;
        transition: ease .3s;

        &:hover {
          color: #4bacff;
        }
      }
    }

    .video-panel {
      box-sizing: border-box;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
      overflow: hidden;
      display: none;
      align-items: center;
      justify-content: center;

      &.show {
        display: flex;
      }

      .close-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        color: #ffffff;
        font-size: 48px;
        cursor: pointer;
      }

      video {
        width: 75%;
        border: 2px solid #ffffff;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .production-video {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .video-item {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 8px;
      }

      span {
        color: #666666;
        font-size: 16px;
        vertical-align: middle;
        transition: ease .3s;

        &:hover {
          color: #4bacff;
        }
      }
    }
  }
}
