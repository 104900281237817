.banner {
  height: 780px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(rgba(9, 33, 71, 1) 0%, rgba(8, 20, 49, 1) 100%);

  .banner-content {
    display: flex;
    flex-direction: row;
  }

  .banner-text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    width: 900px;

    .banner-title {
      font-size: 58px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .banner-sub-title {
      font-size: 28px;
      margin-block-start: 50px;
      margin-block-end: 0;
    }
  }

  .banner-logo {
    width: 511px;
    height: 508px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1780px) {
    .banner-logo {
      width: 470px;
      height: 468px;
    }

    .banner-text-box {
      width: 750px;
    }
  }

  @media screen and (max-width: 1600px) {
    .banner-text-box {
      width: 600px;
    }
  }

  @media screen and (max-width: 1440px) {
    padding: 5%;

    .banner-text-box {
      width: 600px;

      .banner-title {
        font-size: 56px;
      }

      .banner-sub-title {
        font-size: 26px;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    height: 624px;

    .banner-text-box {
      width: 40%;

      .banner-title {
        font-size: 46px;
      }

      .banner-sub-title {
        font-size: 24px;
      }
    }

    .banner-logo {
      width: 409px;
      height: 406px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 500px;

    .banner-text-box {
      .banner-title {
        font-size: 32px;
      }

      .banner-sub-title {
        font-size: 18px;
      }
    }

    .banner-logo {
      width: 294px;
      height: 293px;
    }
  }

  @media screen and (max-width: 480px) {
    height: 350px;
    box-sizing: border-box;
    padding: 0 3% 0 5%;

    .banner-text-box {
      .banner-title {
        font-size: 24px;
      }

      .banner-sub-title {
        font-size: 14px;
        margin-top: 28px;
      }
    }

    .banner-logo {
      width: 185px;
      height: 185px;
    }
  }
}
