.header {
  background-color: #091537;
  height: 81px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;

  p {
    font-size: 28px;
    color: #fff;
  }

  //左侧logo
  img {
    width: 139px;
    height: 38px;
  }

  //右侧导航
  .ant-anchor {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ant-anchor-link {
      margin-right: 50px;

      a {
        font-size: 20px;
        color: #fff;
        //anchor-link未激活时样式
        opacity: 0.5;
      }
    }

    //anchor-link激活时样式
    .ant-anchor-link-active {
      a {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    padding: 0 5%;
  }

  @media screen and (max-width: 800px) {
    .anchor {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    height: 65px;

    img {
      width: 111.2px; //1920图片大小 * 0.8获得
      height: 30.4px;
    }
  }
}

#banner {
  height: 81px;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 65px;
  }
}
