@import '../common.less';

.introdution {
  .row;
  margin-top: 180px;

  .introdution-img {
    width: 48%;
    height: 39%;
    margin-right: 100px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .introdution-mobile-img {
    display: none;
    width: 48%;
    height: 73%;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .introdution-text {
    width: 35%;
    .column;
    justify-content: center;
  }

  .title-zh {
    margin-top: 30px;
  }

  .sub-text {
    margin-top: 60px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 80px;

    .introdution-img {
      display: none;
    }

    .introdution-text {
      width: 44%;
    }

    .introdution-mobile-img {
      display: block;
      margin-right: 5%;
    }

    .title-zh {
      margin-top: 15px;
    }

    .sub-text {
      margin-top: 25px;
    }
  }
}
