@import '../common.less';

.features {
  .column;
  margin-top: 180px;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin-top: 80px;
  }

  .title-box {
    width: 100%;
    .column;
    .flex-center;
  }

  .features-content {
    .row;
    margin-top: 46px;

    @media screen and (max-width: 1440px) {
      flex-flow: wrap;
      margin-top: 30px;
    }

    .features-img {
      width: 33.3%; //由设计图640/1920,下同
      height: 637px;
      margin-right: 46px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        height: 200px;
        margin: 0;

        img {
          object-fit: cover;
        }
      }
    }

    .main-text-box {
      .column;
      justify-content: space-between;
      width: 33%;
      margin-right: 50px;

      .main-text-title {
        color: @main-text;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .main-text-content {
        color: @sub-text;
        font-size: 18px;
      }

      @media screen and (max-width: 1440px) {
        width: 58%;
        margin-right: 0;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        justify-content: start;
        box-sizing: border-box;
        padding: 0 5%;

        .main-text {
          margin-top: 30px;

          .main-text-title {
            font-size: 16px;
            margin-top: 10px;
          }

          .main-text-content {
            font-size: 14px;
          }
        }
      }
    }
  }

  .card-box {
    .column;
    width: 30%;
    box-sizing: border-box;
    padding-right: 30px;

    @media screen and (max-width: 1440px) {
      width: 100%;
      margin: 40px 5% 0 5%;
      padding-right: 0;
    }
  }

  .text-card {
    box-sizing: border-box;
    padding: 20px 30px;
    width: 100%;
    margin-right: 30px;
    border-radius: 1px;
    border: 1px solid rgba(239, 239, 239, 1);
    .column;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .text-card-title {
      color: @main-text;
      font-size: 20px;
      margin-bottom: 15px;
    }

    .text-card-content {
      color: @sub-text;
      font-size: 18px;
    }

    &:hover {
      box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.04);

      /deep/ .text-card-content {
        color: @common-blue;
      }
    }

    @media screen and (max-width: 480px) {
      padding: 20px;

      .text-card-title {
        font-size: 16px;
        margin-bottom: 6px;
      }

      .text-card-content {
        font-size: 14px;
      }
    }
  }
}
