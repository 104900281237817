@import '../common.less';

footer {
  .column;
  align-items: center;
  width: 100%;
  height: 360px;
  background-color: #091537;
  margin-top: 180px;

  .footer-text {
    color: #fff;
    font-size: 18px;
    opacity: 0.3;
  }

  .footer-about {
    height: 222px;
    .column;
    .flex-center;

    .about-title {
      margin-bottom: 29px;
      font-size: 21px;
      color: #fff;
    }

    .about-text-row {
      .row;
      .flex-center;
    }

    .about-line {
      height: 16px;
      width: 1px;
      opacity: 0.1;
      margin: 0 30px;
      background-color: #fff;
    }
  }

  .footer-line {
    height: 1px;
    width: 75%;
    background-color: #d8d8d8;
    opacity: 0.1;
  }

  .footer-copyright {
    height: 137px;
    width: 100%;
    .column;
    .flex-center;
    a:hover {
      opacity: 0.8;
      color: #fff;
    }
  }

  @media screen and (max-width: 480px) {
    height: 230px;

    .footer-text {
      font-size: 12px;
    }

    .footer-about {
      height: 222px;

      .about-title {
        font-size: 15px;
      }

      .about-text-row {
        flex-flow: wrap;
        margin-bottom: 10px;
      }

      .about-line {
        height: 10px;
        margin: 0 20px;
      }
    }

    .footer-copyright {
      height: 86px;
    }
  }
}
